<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <div>
                    <b-row>
                        <b-col cols="12">
                            <h1 class="float-left">{{ i18nT(`Offboarding`) }}</h1>
                            <b-dropdown
                                v-if="iCan( 'offboarding', 'write' )"
                                id="add-new-dropdown"
                                variant="primary"
                                class="float-right"
                                right
                            >
                                <template #button-content>
                                    <feather-icon icon="PlusCircleIcon" />
                                    {{ i18nT(`Offboard employee`) }}
                                </template>

                                <b-dropdown-item>
                                    <div @click="offboardNew" class="text-primary">
                                        <font-awesome-icon class="mr-25 ml-25" :icon="['fas', 'user-plus']" />
                                        {{ i18nT(`Offboard employee`) }}
                                    </div>
                                </b-dropdown-item>

                                <b-dropdown-item 
                                        v-b-modal.modal-export
                                        :disabled="totalDocuments == 0"
                                    >
                                    <div :class="{'text-primary': totalDocuments !== 0}">
                                    <font-awesome-icon class="mr-25 ml-25" :icon="['fas', 'file-export']" />
                                    {{ i18nT(`Export`) }}
                                    </div>
                                </b-dropdown-item>

                            </b-dropdown>
                        </b-col>
                    </b-row>
                    <div class="mb-1">
                    <span class="text-muted">
                      {{ i18nT(`Showing`) }} {{ dataMeta.from }} {{ i18nT(`to`) }}
                      {{ dataMeta.to }} {{ i18nT(`of`) }} {{ dataMeta.of }}
                      {{ i18nT(`entries`) }}
                    </span>
                    </div>
                    <div>
                        <b-row class="mt-2 ml-0 mr-0 mb-2">
                            <b-col
                                v-for="stage in stages"
                                :key="stage.id"
                                class="m-0 p-0 stage-container"
                            >
                                <b-button
                                    class="w-100 h-100 p-0 pt-1 pb-1"
                                    v-bind:class="[matchStage == stage.id ? 'active-stage' : '']"
                                    variant="outline-secondary"
                                    @click.prevent="matchStage = stage.id"
                                >
                                    <div class="count mb-50">{{ stage.count }}</div>
                                    <div class="stage-avatar">{{ stage.label[0] }}</div>
                                    <div class="stage-label">{{ stage.label }}</div>
                                </b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col
                                cols="12"
                                md="6"
                                v-if="controlVisible"
                            >
                                <b-form-group :label="i18nT(`Department`)">
                                    <v-select
                                        v-model="department"
                                        :options="departments"
                                        :reduce="(e) => e.value == '' ? '' : parseInt(e.value)"
                                        label="text"
                                        :clearable="false"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col
                                cols="12"
                                md="6"
                                v-if="controlVisible"
                            >
                                <b-form-group :label="i18nT(`Team`)">
                                    <v-select
                                        v-model="group"
                                        :options="groups"
                                        :reduce="(e) => e.value == '' ? '' : parseInt(e.value)"
                                        label="text"
                                        :clearable="false"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </div>

            <div>
                <div>
                    <div class="row mx-2">
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pl-0">
                            <v-select
                                v-model="perPage"
                                :clearable="false"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="perPageOptions"
                                class="per-page-selector d-inline-block"
                            />
                            <b-dropdown
                                v-if="selectedItems.length > 0 && iCan('offboarding', 'write')"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                class="d-inline ml-1"
                                size="md"
                                text="Actions"
                                variant="outline-secondary"
                            >

                                <b-dropdown-item @click="onBulkDelete(data)">
                                    <feather-icon
                                        color="red"
                                        icon="TrashIcon"
                                    />
                                    <span
                                        class="align-middle ml-50 text-danger"
                                    >{{ i18nT(`Delete`) }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                            <!-- <b-dropdown
                              size="md"
                              class="d-inline ml-1"
                              variant="outline-secondary"
                            >
                              <template v-slot:button-content>
                                <feather-icon icon="ExternalLinkIcon" />&nbsp;&nbsp;{{
                                  i18nT(`Export`)
                                }}
                              </template>
                              <b-dropdown-item @click="onExportExcel()">
                                <feather-icon icon="CopyIcon" />
                                <span class="align-middle ml-50">{{ i18nT(`Excel`) }}</span>
                              </b-dropdown-item>
                              <b-dropdown-item @click="onExportCsv()">
                                <feather-icon icon="CopyIcon" />
                                <span class="align-middle ml-50">{{ i18nT(`CSV`) }}</span>
                              </b-dropdown-item>
                            </b-dropdown> -->
                        </div>
                        <!-- search input -->
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pr-0">
                            <div class="custom-search d-flex justify-content-end">
                                <b-form-group>
                                    <div
                                        class="input-group input-group-merge"
                                        role="group"
                                    >
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <feather-icon icon="SearchIcon"/>
                                            </div>
                                        </div>
                                        <b-form-input
                                            v-model.lazy="searchQuery"
                                            :placeholder="i18nT(`Search`)"
                                            class="d-inline-block"
                                            type="text"
                                        />
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <b-table
                        ref="refDocumentListTable"
                        v-model="currentItems"
                        :empty-html="emptyHtml"
                        :fields="computedColumns"
                        :items="fetchDocuments"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="isSortDirDesc"
                        class="position-relative"
                        primary-key="id"
                        responsive
                        show-empty
                        @refreshed="onTableRefresh"
                    >
                        <template v-if="iCan('offboarding', 'write')" v-slot:head(checkbox)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <b-form-checkbox
                                v-model="selectAllStatus"
                                class="d-inline-block pl-0"
                                @change="onSelectAll"
                            >
                            </b-form-checkbox>
                        </template>

                        <template #head(action)="data">
                            <span>{{ data.label.toUpperCase() }}</span> 
                            <div class="sk-column-control">
                                <b-dropdown
                                    no-caret
                                    variant="button"
                                    toggle-class="p-0"
                                    :right="true"
                                    class="pr-1 pl-1 d-flex sk-column-filter"
                                    v-if="betaFeature"
                                    v-b-tooltip = "i18nT(`Customize columns`)"
                                >
                                    <template #button-content>
                                        <feather-icon
                                            icon="SettingsIcon"
                                            size="18"
                                            class="text-right"
                                        />
                                    </template>

                                    <div class="pl-1 pr-1" v-for="(field, index) in avalableFields" :key="'Fields - ' + index">
                                        <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{ i18nT(field.label) }}</b-form-checkbox>
                                    </div>
                                </b-dropdown>
                            </div>
                        </template>

                        <template #cell(checkbox)="data">
                            <b-form-checkbox
                                v-if="iCan('offboarding', 'write')"
                                v-model="selectedItems"
                                :value="data.item.application.Id"
                            />
                        </template>

                        <template #cell(name)="data">
                            <div class="media">
                                <div class="media-aside align-self-center">
                                    <b-avatar
                                        :src="data.item.user.ImageUrl"
                                        :text="
                                          (data.item.user.FirstName
                                            ? data.item.user.FirstName.charAt(0)
                                            : '') +
                                          (data.item.user.LastName
                                            ? data.item.user.LastName.charAt(0)
                                            : '')
                                        "
                                        :to="{
                                              name: 'view-employee',
                                              params: { id: data.item.user.Id },
                                            }"
                                        class="badge-light-primary"
                                    >
                                    </b-avatar>
                                </div>
                                <div class="media-body">
                                    <b-link
                                        :to="{
                      name: 'view-employee',
                      params: { id: data.item.user.Id },
                    }"
                                        class="font-weight-bold d-block text-nowrap"
                                    >
                                        {{ data.item.user.Label }}
                                    </b-link>
                                    <p class="mb-0">{{ data.item.user.Position }}</p>
                                </div>
                            </div>
                        </template>

                        <!-- <template #cell(labels)="data">
                          <div
                            v-for="(label, index) in getLabels(data.item.labels)"
                            :key="index"
                            class="
                              badge
                              text-capitalize
                              badge-light-primary badge-pill
                              mr-25
                            "
                          >
                            {{ label.title }}
                          </div>
                        </template> -->

                        <template #cell(hiredate)="data">
              <span v-if="data.item.application.OnboardingAt">
                {{ data.item.application.OnboardingAt | date }}
              </span>
                        </template>

                        <template #cell(leavedate)="data">
              <span v-if="data.item.application.OffboardingAt">
                {{ data.item.application.OffboardingAt | date }}
              </span>
                        </template>

                        <template #cell(noticedate)="data">
              <span v-if="data.item.application.OffboardingNotice">
                {{ data.item.application.OffboardingNotice | date }}
              </span>
                        </template>

                        <template #cell(created)="data">
                            {{ data.item.application.CreatedAt | date }}
                        </template>

                        <template #cell(action)="data">
                            <b-dropdown
                                :right="$store.state.appConfig.isRTL"
                                no-caret
                                toggle-class="p-0"
                                variant="link"
                            >
                                <template #button-content>
                                    <feather-icon
                                        class="align-middle text-body"
                                        icon="MoreVerticalIcon"
                                        size="16"
                                    />
                                </template>
                                <b-dropdown-item v-if="iCan('offboarding', 'write')"
                                                 :to="{
                                        name: 'edit-employee',
                                        params: { id: data.item.user.Id },
                                      }"
                                >
                                    <feather-icon icon="EditIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Edit employee`) }}</span>
                                </b-dropdown-item>

                                <b-dropdown-item :to="{
                                        name: 'inbox-send',
                                        params: { id: data.item.user.Id, software: software },
                                      }">
                                    <feather-icon icon="SendIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Send message`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="cancelOffboarding(data)"
                                                 v-if="iCan('onboarding', 'write')"
                                >
                                    <feather-icon icon="XSquareIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Cancel offboarding`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="iCan('offboarding', 'write')"
                                                 @click="moveStage(data)"
                                >
                                    <feather-icon icon="LogOutIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Change dates`) }}</span>
                                </b-dropdown-item>

                                <b-dropdown-divider/>
                                <b-dropdown-item
                                    v-for="(stage, index) in otherStages"
                                    :key="index"
                                    @click="moveOtherStage(data, stage)"
                                >
                                    <span class="align-middle ml-50">{{ stage.text }}</span>
                                </b-dropdown-item>
                                <b-dropdown-divider/>
                                <b-dropdown-item v-if="iCan('offboarding', 'write')"
                                                 @click="onDelete(data)"
                                >
                                    <feather-icon
                                        color="red"
                                        icon="TrashIcon"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{
                                            i18nT(`Delete`)
                                        }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>
                </div>
                <!-- Disable grid view -->
                <b-tabs
                    v-if="false"
                    align="center"
                    pills
                >
                    <b-tab active>
                        <template #title>
                            <feather-icon
                                icon="ListIcon"
                                size="24"
                                style="margin: 0"
                            />
                        </template>
                    </b-tab>

                    <b-tab>
                        <template
                            #title
                            style="text-align: center"
                        >
                            <feather-icon
                                icon="GridIcon"
                                size="24"
                                style="margin: 0"
                            />
                        </template>
                        <div class="task-container">
                            <b-row class="task-item">
                                <b-col
                                    v-for="document in employees"
                                    :key="document.Id"
                                    cols="4"
                                >
                                    <b-card style="border: 1px solid rgba(0, 0, 0, 0.07)">
                                        <b-card-body class="p-0">
                                            <b-row>
                                                <b-avatar/>
                                                <b-col>
                                                    <div
                                                        class="cv-card"
                                                        style="min-height: 180px"
                                                    >
                                                        <h3>{{ document.user.Label }}</h3>
                                                        <h5>{{ document.user.Position }}</h5>
                                                        <p>{{ document.user.Presentation }}</p>
                                                        <span>{{ i18nT(`Skills `) }}</span><br/>
                                                        <span>Experience:
                              {{ document.user.Experience }} year(s)</span><br/>
                                                        <span>Salary: {{ document.user.RequiredSalary }}</span><br/>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </b-card-body>

                                        <b-card-footer class="
                        pb-0
                        pt-1
                        pr-1
                        pl-1
                        mt-2
                        ml-n2
                        mr-n2
                        text-top text-right
                      ">
                                            <b-dropdown
                                                no-caret
                                                toggle-class="p-0"
                                                variant="link"
                                            >
                                                <template #button-content>
                                                    <b-button>{{ i18nT(` Actions `) }}</b-button>
                                                </template>
                                                <b-dropdown-item :to="{
                            name: 'edit-document',
                            params: { id: document.Id },
                          }">
                                                    <feather-icon icon="EditIcon"/>
                                                    <span class="align-middle ml-50">{{
                                                            i18nT(`Edit`)
                                                        }}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="onDelete({ item: document })">
                                                    <feather-icon
                                                        color="red"
                                                        icon="TrashIcon"
                                                    />
                                                    <span
                                                        class="align-middle ml-50"
                                                        style="color: red"
                                                    >{{
                                                            i18nT(`Delete`)
                                                        }}</span>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </b-card-footer>
                                    </b-card>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                </b-tabs>

                <div class="mx-1 mb-2">
                    <b-row>
                        <b-col
                            class="
                                d-flex
                                align-items-center
                                justify-content-center justify-content-sm-start
                              "
                            cols="12"
                            sm="6"
                        >
                            <b-form-checkbox
                                v-if="iCan('offboarding', 'write')"
                                v-model="selectAllStatus"
                                class="d-inline-block mx-1"
                                @change="onSelectAll"
                            >
                                {{ i18nT(`All`) }}
                            </b-form-checkbox>
                            <span class="text-muted">
                                {{ i18nT(`Showing`) }} {{ dataMeta.from }} {{ i18nT(`to`) }}
                                {{ dataMeta.to }} {{ i18nT(`of`) }} {{ dataMeta.of }}
                                {{ i18nT(`entries`) }}
                            </span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            class="
                                d-flex
                                align-items-center
                                justify-content-center justify-content-sm-end
                              "
                            cols="12"
                            sm="6"
                        >
                            <b-pagination
                                v-model="currentPage"
                                :per-page="perPage"
                                :total-rows="totalDocuments"
                                class="mb-0 mt-1 mt-sm-0"
                                first-number
                                last-number
                                next-class="next-item"
                                prev-class="prev-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-card>

        <!-- MODAL CREATE/EDIT PIPELINE-->
        <b-modal
            id="modal-pipeline"
            :ok-title="i18nT(`Save`)"
            :title="i18nT(`New / edit stage`)"
            no-close-on-backdrop
            ok-only
        >
            <span>{{ i18nT(`Create / edit stages in your pipeline.`) }}</span>
            <validation-observer ref="simpleRules">
                <b-form class="mt-2">
                    <b-row>
                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Stage Name`)"
                                label-for="pipelineName"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Stage Name"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="pipelineName"
                                        :placeholder="i18nT(`E.g. interview template`)"
                                        :state="errors.length > 0 ? false : null"
                                        name="subject"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Stage Type`)"
                                label-for="stageType"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Stage Type"
                                    rules="required"
                                >
                                    <b-form-select
                                        v-model="stageType"
                                        :options="stageTypes"
                                        :state="errors.length > 0 ? false : null"
                                        label="title"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>

        <!-- MODAL -->
        <b-modal
            id="modal-change-status"
            :ok-title="i18nT(`Save`)"
            :title="i18nT(`Offboarding`)"
            no-close-on-backdrop
            ok-only
            @hidden="resetChangeStatus"
            @ok="validationChangeStatus"
        >

            <validation-observer ref="changeStatusForm">
                <b-form class="mt-2">
                    <b-row>
                        <b-col sm="12">
                            <b-form-group :label="i18nT(`Employee`)">
                                <v-select
                                    v-if="canSelectEmployee"
                                    v-model="selectedItems"
                                    :options="availableEmployees"
                                    :reduce="(e) => e.value == '' ? '' : parseInt(e.value)"
                                    label="title"
                                />

                                <div v-else-if="modalUser" class="d-flex pb-1 pt-05 border-bottom mb-05">
                                    <b-avatar
                                        :src="modalUser.user.ImageUrl"
                                        :text="(modalUser.user.FirstName ? modalUser.user.FirstName.charAt(0): '') +
                                          (modalUser.user.LastName ? modalUser.user.LastName.charAt(0) : '')
                                        "
                                        size="4em"
                                        class="badge-light-primary"
                                    >
                                    </b-avatar>
                                    <h4 class="pt-1 ml-1">{{modalUser.user.Label}}</h4>
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12">
                            <b-form-group>
                                <b-form-checkbox
                                    v-model="changeStatusNotification"
                                    :checked="changeStatusNotification"
                                    switch
                                >
                                    {{ i18nT(`Send notification email?`) }}
                                </b-form-checkbox>
                            </b-form-group>
                            <div v-if="changeStatusNotification">
                                <b-form-group
                                    :label="i18nT(`Message Template`)"
                                    label-for="messageTemplate"
                                >
                                    <b-form-select
                                        v-model="statusChangeTemplate"
                                        :options="mesasgeTemplates"
                                        text-field="Label"
                                        value-field="Id"
                                        @change="changeTemplate"
                                    />
                                </b-form-group>
                                <b-form-group
                                    :label="i18nT(`Message`)"
                                    label-for="messageTemplate"
                                >
                                    <b-form-textarea v-model="templateMessage"/>
                                </b-form-group>
                            </div>

                            <b-row class="mt-2">
                                <b-col md="12">
                                    <b-form-group
                                        :label="this.i18nT(`Notice Date`)"
                                        class="required"
                                        label-for="message"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Notice Date"
                                            rules="required"
                                        >
                                            <date-picker
                                                v-model="noticeDate"
                                                :state="errors.length > 0 ? false : null"
                                                placeholder=""
                                                reset-button
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="12">
                                    <b-form-group
                                        :label="i18nT(`Departure date`)" class="required"
                                        label-for="message"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Departure date"
                                            rules="required"
                                        >
                                            <date-picker
                                                v-model="departureDate"
                                                :state="errors.length > 0 ? false : null"
                                                placeholder=""
                                                reset-button
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="12">
                                    <b-form-group
                                        :label="i18nT(`Reason`)"
                                        label-for="message"
                                    >
                                        <b-form-select
                                            v-model="statusChangeReason"
                                            :options="reasonList"
                                            placeholder=""
                                        />
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-form-group
                                :label="i18nT(`Comment`)"
                                label-for="messageTemplate"
                            >
                                <b-form-textarea v-model="statusChangeComment"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>

        <!-- MODAL -->
        <b-modal
            id="modal-change-other-status"
            :ok-title="i18nT(`Change Status`)"
            :title="i18nT(`Change Status`)"
            no-close-on-backdrop
            ok-only
            @ok="validationChangeOtherStatus"
        >
            <validation-observer ref="changeOtherStatusForm">
                <b-form class="mt-2">
                    <b-row>
                        <b-col sm="12">
                            <b-form-group>
                                <b-form-checkbox
                                    v-model="changeStatusNotification"
                                    :checked="changeStatusNotification"
                                    switch
                                >
                                    {{ i18nT(`Send notification email?`) }}
                                </b-form-checkbox>
                            </b-form-group>
                            <b-form-group
                                v-if="changeStatusNotification"
                                :label="i18nT(`Message`)"
                                label-for="messageTemplate"
                            >
                                <b-form-textarea v-model="templateMessage"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>

        <modal-export module="employees" :allFilters="allFilters"/>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BForm,
    BFormGroup,
    BAvatar,
    // BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTabs,
    BTab,
    // BListGroup, BListGroupItem,
    BCardFooter,
    BCardBody,
    BFormCheckbox,
    BFormSelect,
    BLink,
    BFormTextarea,
    //BFormDatepicker,
    BDropdownDivider,
    BFormInput,
    VBTooltip,
    // VBModal
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import useEmployeeList from './useEmployeeList'
import {avatarText} from '@core/utils/filter'
import {
    dictToSelectArrayWithTitle,
    dictToSelectArray, dictToSelectArrayWithLabel
} from '@core/utils/utils'
import router from '@/router'
import Ripple from 'vue-ripple-directive'
import VoerroTagsInput from '@voerro/vue-tagsinput'
import useAuth from '@/auth/useAuth'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import DatePicker from '@core/components/DatePicker.vue'
import ModalExport from '@/views/common/ModalExport/ModalExport.vue'

// import _ from 'lodash'

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BTable,
        BAvatar,
        // BLink,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTabs,
        BTab,
        // BListGroup,
        // BListGroupItem,
        BCardFooter,
        BCardBody,
        vSelect,
        BLink,
        VoerroTagsInput,
        ValidationProvider,
        ValidationObserver,
        BForm,
        BFormCheckbox,
        BFormGroup,
        BFormSelect,
        BFormTextarea,
        //BFormDatepicker,
        BDropdownDivider,
        BFormInput,
        DatePicker,
        ModalExport
        // VBModal
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip
        // 'b-modal': VBModal,
    },
    data() {
        return {
            pagination: {},
            selectedItems: [],
            canSelectEmployee: true,
            availableEmployees: [],
            offboardPerson: [],
            currentItems: [],
            selectAllStatus: false,
            departments: [],
            stages: [],
            actionStages: [],
            software: useAuth.getCurrentSoftware(),
            targetStage: {},
            required,
            tagsList: [],
            selectedTags: [],
            changeStatusNotification: true,
            mesasgeTemplates: [],
            templateMessage: '',
            noticeDate: null,
            departureDate: null,
            modalUser: null,
            reasonList: [],
            statusChangeTemplate: null,
            statusChangeComment: '',
            statusChangeReason: null,
            otherStages: [],
            selectedOtherStage: null,
            groups: [],
            customTableColumns: [
                {key: 'checkbox', label: '', thClass: 'tbl-chk', tdClass: 'tbl-chk'},
                {key: 'name', label: this.i18nT(`Name`), sortable: true, thClass: 'pl-1', tdClass: 'pl-1'},
                // { key: 'labels', sortable: true },
                {key: 'hiredate', label: this.i18nT(`Hired on`), sortable: true, thClass: "sk-header-content" },
                {key: 'leavedate', label: this.i18nT(`Departure date`), sortable: true, thClass: "sk-header-content" },
                {key: 'noticedate', label: this.i18nT(`Notice date`), sortable: true, thClass: "sk-header-content" },
                {key: 'created', label: this.i18nT(`Created`), sortable: true},
                {
                    key: 'action',
                    label: this.i18nT(`Actions`),
                    thClass: 'tbl-actions',
                    tdClass: 'sticky-column',
                    stickyColumn: true
                },
            ],
            avalableFields: [
              {label: this.i18nT(`Created`), key: 'created', visible: true},
              {label: this.i18nT(`Hired on`), key: 'hiredate', visible: true},
              {label: this.i18nT(`Notice date`), key: 'noticedate', visible: true},
            ],
            controlVisible: true,
        }
    },
    computed: {
        emptyHtml() {
          return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>"
              + this.i18nT(`Here, you can create and manage your offboardings. No offboarding has been created yet.`) + "</h4>"
              +"<button class='btn btn-outline-secondary mt-2' id='offboardButton'>" + this.i18nT(`Create offboarding`) + "</button>"
        },
        computedColumns() {

            let col = [...this.customTableColumns]

            for(let i = 0; i < this.avalableFields.length; i++) {
              if(!this.avalableFields[i].visible) {
                col = col.filter(c => c.key !== this.avalableFields[i].key)
              }
            }
            useAuth.setColumnPreferences('offboarding', this.avalableFields);

            return col
        },
        allFilters() {
            const params = {
                export: 1,
                sort_order: this.isSortDirDesc ? 'DESC' : 'ASC',
                search: this.searchQuery,
                sort_by: this.sortBy,
            }

            if (this.department != null) {
                params['filters[department]'] = this.department
            }
            if (this.group != null) {
                params['filters[group]'] = this.group
            }

            params['pipeline'] = 'offboarding'
            params['stage'] = this.matchStage

            return params
        }
    },
    created() {

      let storePreferences = useAuth.getColumnPreferences('offboarding');
      if(storePreferences) {
        this.avalableFields = storePreferences
      }

        this.departments.unshift({
            value: '',
            text: this.i18nT(`All`)
        })

        //new Promise((res) => {
        this.$http.get(`employees/offboardingList`).then(({data}) => {
            //this.availableEmployees = dictToSelectArray(data.data.users_list)
            this.availableEmployees = dictToSelectArrayWithLabel(data.data);
            console.log("Available to offboard: ", this.availableEmployees);

        })
        //}),

        this.$http.get(`system/departments`).then(({data}) => {
            this.departments.push(...dictToSelectArray(data.data))
        })

        this.$http.get(`settings/labels`).then(({data}) => {
            this.tagsList = data.data.map(tag => ({
                key: tag.Id,
                value: tag.Label
            }))
        })

        this.$http.get(`settings/messageTemplates`).then(({data}) => {
            this.mesasgeTemplates = data.data.message_templates.filter(
                template => template.Type === 'OFFBOARD'
            )
            if(this.mesasgeTemplates.length > 0) {
                this.templateMessage = this.mesasgeTemplates[0].Message
                this.statusChangeTemplate = this.mesasgeTemplates[0].Id
            }
        })

        this.$http.get(`system/offboardingReasons`).then(({data}) => {
            this.reasonList = dictToSelectArray(data.data)
            this.statusChangeReason = this.reasonList[0].value
        })

        this.$http
            .get(
                `employees/notTimeDependantStages?pipeline=${
                    router.currentRoute.meta.onboarding === 1
                        ? 'onboarding'
                        : 'offboarding'
                }`
            )
            .then(({data}) => {
                if (this.iCan('offboarding', 'write')) {
                    this.otherStages = dictToSelectArray(data.data.stages)
                }
            })

        this.groups.unshift({
            value: '',
            text: this.i18nT(`All`)
        })
        this.$http.get(`employees/employeeGroups`).then(({data}) => {
            this.groups.push(
                ...data.data.employee_groups.map(item => ({
                    value: item.group.Id,
                    text: item.group.Label
                }))
            )
        })

        this.boardingMode = router.currentRoute.meta.onboarding ? 'on' : 'off'

        this.loadStages()

        this.fetchDocuments(null,this.determineVisibility);
    },
    methods: {
        determineVisibility(invoiceArray) {
          if (invoiceArray.length === 0) {
            this.controlVisible = false;
          }
        },
        cancelOffboarding(data){
            let applicationID = data.item.application.Id;
            this.$swal({
                title: this.i18nT(`Are you sure want to cancel the offboarding?`),
                text: this.i18nT(`You will have to restart the offboarding process if you change your mind!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, cancel!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    let formData = new FormData();
                    formData.append('application_id', applicationID)
                    formData.append('stage', 'offboard')
                    formData.append('cancel_offboarding', 1)
                    this.$http
                        .post('employees/changeStage', formData).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: this.i18nT(`Offboarding canceled!!`),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        this.refetchData()
                        this.loadStages()
                    }).catch((err) => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.errorMessage(err),
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        })
                    })
                }
            })
        },
        resetChangeStatus() {
            this.selectedItems = [];
            this.modalUser = null;
            this.noticeDate = null;
            this.departureDate = null;
            this.statusChangeReason = null;
            this.statusChangeComment = null;
            this.canSelectEmployee = true;
        },
        loadStages() {
            this.$http
                .get(
                    `employees/stages?pipeline=${
                        router.currentRoute.meta.onboarding === 1
                            ? 'onboarding'
                            : 'offboarding'
                    }`
                )
                .then(({data}) => {
                    // this.actionStages = this.stages = data.data
                    this.actionStages = data.data;
                    this.stages = data.data.filter(stage => stage.count !== 0);
                    try {
                        if (router.currentRoute.params.id) {
                            this.matchStage = router.currentRoute.params.id
                        }
                    } catch (ex) {
                        console.log(ex)
                    }
                })
        },
        removeLabel(label) {
            console.log(label)
        },
        getLabels(labelArray) {
            return dictToSelectArrayWithTitle(labelArray)
        },
        getTypeName(typeId) {
            const document = this.documentTypes.find(type => type.value == typeId)
            return document ? document.text : ''
        },

        getAvatarName(name) {
            if (name) {
                return name
                    .split(' ')
                    .map(namePart => {
                        return namePart[0]
                    })
                    .join('')
                    .toUpperCase()
            } else {
                return ''
            }
        },
        onTableRefresh() {
            this.selectedItems = []
        },
        onBulkDelete() {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`employees?id=${this.selectedItems.join(',')}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Deleted!`),
                                text: this.i18nT(`The task has been deleted.`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.selectedItems = []
                            this.selectAllStatus = false
                            this.refetchData()
                        })
                }
            })
        },
        onSelectAll(val) {
            if (val) {
                this.selectedItems = this.currentItems.map(item => item.application.Id)
            } else {
                this.selectedItems = []
            }
        },

        getCandidateIds() {
            return this.employees
                .filter(cv => this.selectedItems.includes(cv.application.Id))
                .map(cv => cv.user.Id)
        },
        moveStage(data) {
            this.selectedItems = data.item.application.Id;
            this.modalUser = data.item;
            this.noticeDate = data.item.application.OffboardingNotice;
            this.departureDate = data.item.application.OffboardingAt;
            this.statusChangeReason = data.item.application.OffboardingReason;
            this.statusChangeComment = data.item.application.OffboardingComment;
            this.$bvModal.show('modal-change-status')
            this.changeStatusNotification = false
            this.canSelectEmployee = false
        },
        offboardNew() {
            this.$bvModal.show('modal-change-status')
            this.changeStatusNotification = true
            this.canSelectEmployee = true
        },
        moveOtherStage(data, stage) {
            this.selectedOtherStage = stage.value
            this.selectedItems = [data.item.application.Id]
            this.$bvModal.show('modal-change-other-status')
        },
        onDelete(candidate) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result) {
                    const formData = new FormData()
                    formData.append('id', candidate.item.user.Id)

                    this.$http
                        .delete(`employees`, formData)
                        .then(() => {
                            this.selectedItems = []
                            this.selectAllStatus = false
                            this.refetchData()
                            this.loadStages()
                        })
                }
            })
        },
        validationChangeOtherStatus(event) {
            event.preventDefault()
            this.$refs.changeOtherStatusForm.validate().then(success => {
                if (success) {

                    const formData = new FormData()

                    if (Array.isArray( this.selectedItems) && this.selectedItems.length > 0) {
                        formData.append('application_id', this.selectedItems.join(','))
                    } else {
                        formData.append('application_id', this.selectedItems)
                    }
                    formData.append('stage', 'offboard')
                    formData.append('stage_custom', this.selectedOtherStage)
                    formData.append('message_template', this.templateMessage)

                    this.$http
                        .post('employees/changeStage', formData)
                        .then(() => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Successfully moved to offboard`,
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.selectedItems = []
                            this.$bvModal.hide('modal-change-other-status')




                            this.selectedOtherStage = null
                            this.templateMessage = null
                            this.refetchData()
                            this.loadStages()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        validationChangeStatus(event) {
            event.preventDefault()
            this.$refs.changeStatusForm.validate().then(success => {
                if (success) {

                    const formData = new FormData()

                    if (Array.isArray( this.selectedItems) && this.selectedItems.length > 0) {
                        formData.append('application_id', this.selectedItems.join(','))
                    } else {
                        formData.append('application_id', this.selectedItems)
                    }

                    formData.append('stage', 'offboard')
                    formData.append('message_template', this.templateMessage)

                    formData.append('offboarding_notice', this.noticeDate)
                    formData.append('offboarding_at', this.departureDate)
                    formData.append('offboarding_reason', this.statusChangeReason)
                    formData.append('offboarding_comment', this.statusChangeComment)

                    this.$http
                        .post('employees/changeStage', formData)
                        .then(() => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.i18nT(`Successfully moved to offboarding stage`),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.selectedItems = []
                            this.$bvModal.hide('modal-change-status')
                            this.stage = null
                            this.message_template = null
                            this.noticeDate = null
                            this.departureDate = null
                            this.statusChangeReason = null
                            this.statusChangeComment = null
                            this.refetchData()
                            this.loadStages()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        changeTemplate() {
            if (this.statusChangeTemplate) {
                this.templateMessage =
                    this.mesasgeTemplates.find(
                        template => template.Id === this.statusChangeTemplate
                    ).Message ?? ''
            }
        },
        onExportExcel() {
        },
        onExportCsv() {
        }
    },
    setup() {
        const {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            department,
            group,
            employees,
            refetchData,
            matchStage
        } = useEmployeeList(
            router.currentRoute.meta.onboarding === 1 ? 'onboarding' : 'offboarding'
        )

        return {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            department,
            group,
            avatarText,
            employees,
            refetchData,
            matchStage
        }
    },
    mounted(){
      if(document.getElementById('offboardButton')){
        document.getElementById('offboardButton').addEventListener('click', this.offboardNew);
      }
      },
    beforeUnmount(){
      document.getElementById('offboardButton').removeEventListener('click', this.offboardNew);
    },
    watch: {
        offboardPerson: function (newVal) {
            console.log(newVal)
        }
    }
}
</script>

<style lang="scss">
.task-container {
    .task-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .line-item {
        margin-top: 4px;
    }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';

.section-title {
    font-size: 11px;
}

.document-name {
    display: inline;
    line-height: 32px;
}

.document-actions-bar {
    float: right;
}

.ql-editor {
    min-height: 200px;
}

.tag-container {
    display: inline-block;
    background: #e6e6e6;
    color: #626262;
    padding: 3px 9px;
    font-size: 11px;
    font-weight: 600;
    margin: 4px;
    border-radius: 5px;

    .handler {
        cursor: pointer;
    }
}

.cv-card {
    min-height: 180px;

    span {
        font-size: 12px;
    }
}

.stage-container {
    background: white;
    border: 1px solid gray;
    border-right: 0px;

    button {
        border: none;
        border-radius: 0px;
    }

    button:focus,
    button:active {
        background-color: $primary !important;
    }

    .count {
        font-size: 30px;
        color: black;
        font-weight: 300;
    }

    .stage-label {
        color: black;
        font-size: 10px;
    }

    .stage-avatar {
        color: white;
        width: 14px;
        height: 14px;
        background: black;
        display: inline-block;
        margin-right: 4px;
        padding-top: 1px;
        font-size: 10px;
    }
}

.stage-container:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.stage-container:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-right: solid 1px gray;
}

.stage-container {
    background: white;
    border: 1px solid gray;
    border-right: 0px;
    overflow: hidden;

    button {
        border: none !important;
        border-radius: 0px;
    }

    button:focus,
    button:active {
        background-color: $primary !important;
    }

    .count {
        font-size: 30px;
        color: #5e5873;
        font-weight: 300;
    }

    .stage-label {
        color: #5e5873;
        font-size: 10px;
    }

    .stage-avatar {
        color: white;
        width: 14px;
        height: 14px;
        background: #5e5873;
        display: inline-block;
        margin-right: 4px;
        padding-top: 1px;
        font-size: 10px;
    }
}

.stage-container:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.stage-container:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-right: solid 1px gray;
}

.active-stage {
    background-color: $primary !important;

    span {
        color: white !important;
    }
}

/* The input */
.tags-input {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.tags-input input {
    flex: 1;
    background: transparent;
    border: none;
}

.tags-input input:focus {
    outline: none;
}

.tags-input input[type='text'] {
    color: #495057;
}

.tags-input-wrapper-default {
    padding: 0.5em 0.25em;

    background: #fff;

    border: 1px solid transparent;
    border-radius: 0.25em;
    border-color: #dbdbdb;
}

.tags-input-wrapper-default.active {
    border: 1px solid #8bbafe;
    box-shadow: 0 0 0 0.2em rgba(13, 110, 253, 0.25);
    outline: 0 none;
}

/* The tag badges & the remove icon */
.tags-input span {
    margin-right: 0.3em;
}

.tags-input-remove {
    cursor: pointer;
    position: absolute;
    display: inline-block;
    right: 0.3em;
    top: 0.3em;
    padding: 0.5em;
    overflow: hidden;
}

.tags-input-remove:focus {
    outline: none;
}

.tags-input-remove:before,
.tags-input-remove:after {
    content: '';
    position: absolute;
    width: 75%;
    left: 0.15em;
    background: #5dc282;

    height: 2px;
    margin-top: -1px;
}

.tags-input-remove:before {
    transform: rotate(45deg);
}

.tags-input-remove:after {
    transform: rotate(-45deg);
}

/* Tag badge styles */
.tags-input-badge {
    position: relative;
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tags-input-badge-pill {
    padding-right: 1.25em;
    padding-left: 0.6em;
    border-radius: 10em;
}

.tags-input-badge-pill.disabled {
    padding-right: 0.6em;
}

.tags-input-badge-selected-default {
    color: #212529;
    background-color: #f0f1f2;
}

/* Typeahead */
.typeahead-hide-btn {
    color: #999 !important;
    font-style: italic;
}

/* Typeahead - badges */
.typeahead-badges > span {
    margin-top: 0.5em;
}

.typeahead-badges > span {
    cursor: pointer;
    margin-right: 0.3em;
}

/* Typeahead - dropdown */
.typeahead-dropdown {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    width: 100%;
    z-index: 1000;
}

.typeahead-dropdown li {
    padding: 0.25em 1em;
    cursor: pointer;
}
</style>
